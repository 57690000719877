<!--
 * @Author: xr
 * @Date: 2022-02-17 16:00:26
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 09:21:23
 * @FilePath: \postStation-office\src\views\search.vue
-->
<template>
   <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between">
        <a-col :span="7">
          <div class="right-side-assembly">
            <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
            <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
          </div>
        </a-col>
        <a-col :span="17">
          <div  class="sub-component-background-color" style="width: 695px;margin-left: 4px;">
            <a-tabs type="card" @change="tabChange" v-model="type">
              <a-tab-pane key="1" tab="项目" style="width:50%;margin: 0 auto;">
                <a-input-search placeholder="请输入关键字" enter-button @search="onSearch" v-model="searchData.name" allowClear/>
              </a-tab-pane>
              <a-tab-pane key="2" tab="需求" style="width:50%;margin: 0 auto;">
                <a-input-search placeholder="请输入关键字" enter-button @search="onSearch" v-model="searchData.name" allowClear/>
              </a-tab-pane>
            </a-tabs>
            <div class="list">
              <a-row class="item" v-for="item in list" :key="item.id">
                <div @click="details(item.id)">
                  <span class="text-over-ellipsis" style="width: 99%;">{{item.productName}}</span>
                  <br>
                  <span>行业分类：{{item.applicationArea_dictText}}</span>
                  <span>时间：{{item.createTime.substring(0.10)}}</span>
                </div>
              </a-row>
            </div>
          </div>
          <a-row type="flex" justify="center" style="margin:20px 0px;">
          <a-pagination v-show="list.length" v-model="pageNo" :total="total" @change="onChange" show-less-items />
        </a-row>
        </a-col>
      </a-row>
    </div>
   </div>
</template>
<script>
import FindDemand from '@comp/findDemand'
import { getDict } from '@/api/index'
import { demandSearch, domainQueryId } from '@/api/project'
import { Page } from '@/mixins/page'

export default {
  name: 'Search',
  mixins: [Page],
  components: {
    FindDemand
  },
  data() {
    return {
      list: [],
      technicalProjectContent: [],
      searchData: {
        name: '',
        mainResearchAreas: ''
      },
      type: '1',
      stationId: ''
    }
  },
  methods: {
    /**
     * @description:tab切换
     * @param {*} tab
     */
    tabChange(tab) {
      if (tab === '1') {
        this.type = '1'
        this.onSearch()
      } else {
        this.type = '2'
        this.onSearch()
      }
    },
    onSearch() {
      // 在地址栏存参数
      if (this.stationId) {
        window.open(window.location.origin + window.location.pathname + '?name=' + this.searchData.name + '&type=' + this.type + '&stationId=' + this.stationId, '_self')
      } else {
        window.open(window.location.origin + window.location.pathname + '?name=' + this.searchData.name + '&type=' + this.type, '_self')
      }
      // this.getList()
    },
    /**
     * @description:获取列表
     */
    getList() {
      // 请求参数
      const param = this.$route.query
      param.pageNo = this.pageNo
      param.pageSize = this.pageSize
      // 按照需求名称关键词搜索和应用领域字典搜索分页
      demandSearch(param).then(res => {
        this.list = res.result.records
        this.total = res.result.total * 1
      })
    },
    /**
     * @description:项目需求详情
     */
    details(id) {
      domainQueryId({ id: id }).then(res => {
        if (this.stationId !== '') {
          this.$router.push({ name: 'detail', query: { stationId: this.stationId }, params: res.result })
        } else {
          this.$router.push({ name: 'detail', params: res.result })
        }
      })
    }
  },
  activated() {
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    // 赋值搜索框
    if (JSON.stringify(this.$route.query) !== '{}') {
      this.searchData.name = this.$route.query.name ? this.$route.query.name : ''
      this.type = this.$route.query.type ? this.$route.query.type : '1'
    } else {
      this.searchData.name = ''
      this.type = '1'
    }
    // 赋值tabs
    this.stationId = this.$route.query.stationId
    this.getList()
  }
}
</script>
<style lang="scss" scoped>
.list{
  min-height: 70vh;
  padding-left: 10px;
  .item{
    cursor: pointer !important;
    padding: 10px 0px;
    span:first-child{
      font-size: 18px;
      text-decoration: underline;
      color: #0c4dc5;
    }
    span{
      color:#747474;
      font-size: 12px;
    }
    span:last-child{
      margin-left: 20px;
    }
  }
}
@import "../assets/css/newsInformation.css";
</style>
